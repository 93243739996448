import { GetStaticPaths, GetStaticProps } from 'next';
import {
  getFooter,
  getHeader,
  getMenu,
  getPage,
  WagtailApiResponseError,
  // getRequest,
  // getRedirect,
  // getAllPages,
  // getMenu,
  // WagtailApiResponseError,
} from '@api/wagtail';
import LazyViews from '@views/LazyViews';
import type { PageData, Streamfield } from '@/types';
import { loaders, Streamfields } from '@/streamfields';

const isProd = process.env.NODE_ENV === 'production';

type Props = {
  pageData: PageData;
  blocksLoaderData: Partial<Record<Streamfields, unknown>>;
};

function CatchAllPage(props: Props) {
  const { pageData, blocksLoaderData } = props;

  const Component = LazyViews[pageData.meta.type];
  if (!Component) {
    return <h1>Component {pageData.meta.type} not found</h1>;
  }

  // @ts-expect-error
  return <Component {...pageData} blocksLoaderData={blocksLoaderData} />;
}

export default CatchAllPage;

export const getStaticProps: GetStaticProps = async context => {
  const { params } = context;
  let path = params?.path ?? [];
  if (Array.isArray(path)) path = path.join('/');

  try {
    const [{ json: pageData }, { json: footerData }, { json: headerData }, { json: menuData }] =
      await Promise.all([getPage(path), getFooter(), getHeader(), getMenu()]);
    // const [{ json: pageData }] = await Promise.all([getPage(path)]);

    const blocksLoaderData = new Map<string, unknown>();
    let streamfields: Streamfield[] = [
      // @ts-expect-error
      ...(pageData.content ?? []),
      // @ts-expect-error
      ...(pageData.before_form ?? []),
      // @ts-expect-error
      ...(pageData.after_form ?? []),
    ];

    const containerFields = streamfields.filter(field => field.type === 'container');
    // eslint-disable-next-line no-restricted-syntax
    for (const field of containerFields) {
      streamfields = streamfields.concat(field?.value?.content ?? []);
    }

    if (streamfields.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const streamfield of streamfields) {
        const blockLoader = loaders[streamfield.type as Streamfields];
        // eslint-disable-next-line no-await-in-loop
        const data = await blockLoader?.(streamfield.value);
        if (data) blocksLoaderData.set(streamfield.id, data);
      }
    }

    return {
      props: {
        pageData,
        footerData,
        headerData,
        menuData,
        blocksLoaderData: Object.fromEntries(blocksLoaderData),
      },
      revalidate: 3600,
    };
  } catch (err) {
    if (!(err instanceof WagtailApiResponseError)) {
      throw err;
    }

    if (!isProd && err.response.status >= 500) {
      const html = await err.response.text();
      return {
        props: {
          componentName: 'PureHtmlPage',
          componentProps: { html },
        },
      };
    }

    if (err.response.status >= 500) {
      throw err;
    }

    return { notFound: true };
  }
};

// Determine which pages should be pre-rendered at build time
export const getStaticPaths: GetStaticPaths = () => {
  // const { json: data } = await getAllPages();

  // let htmlUrls = data.items.map((x) => x.relativeUrl);
  // htmlUrls = htmlUrls.filter((x) => x);
  // htmlUrls = htmlUrls.map((x) => x.split("/"));
  // htmlUrls = htmlUrls.map((x) => x.filter((y) => y));
  // htmlUrls = htmlUrls.filter((x) => x.length);

  // const paths = htmlUrls.map((x) => ({ params: { path: x } }));

  return {
    paths: [],
    fallback: 'blocking',
  };
};
