import dynamic from 'next/dynamic';

const pages = {
  'home.HomePage': dynamic(() => import('./HomePage')),
  'flex.FlexPage': dynamic(() => import('./FlexPage')),
  'magazin.MagazinPage': dynamic(() => import('./MagazinPage')),
  'magazin.MagazinListingPage': dynamic(() => import('./ListingPage')),
  'forms.FormPage': dynamic(() => import('./FormPage')),
  'bonuswelt.BonusweltListingPage': dynamic(() => import('./BonusweltListingPage')),
  'order.OptInPage': dynamic(() => import('./OrderOptInPage')),
  PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
};

export type Pages = keyof typeof pages;

export default pages;
